import { render, staticRenderFns } from "./ReZoning.vue?vue&type=template&id=5b073b38&scoped=true"
import script from "./ReZoning.vue?vue&type=script&lang=js"
export * from "./ReZoning.vue?vue&type=script&lang=js"
import style0 from "./ReZoning.vue?vue&type=style&index=0&id=5b073b38&prod&scoped=true&lang=css"
import style1 from "./ReZoning.vue?vue&type=style&index=1&id=5b073b38&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b073b38",
  null
  
)

export default component.exports